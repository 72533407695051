import React from 'react';

import { NavLink, useLocation } from "react-router-dom";


import './nav.css';
//import imgFood from '../assets/hotdog1.svg';
// import imgLights from '../assets/standard_lights2.svg';
// import imgMachine from '../assets/machineicon1.svg';
// import imgSupport from '../assets/support2.svg';
// import imgSystem from '../assets/system1.svg';
// import imgSettings from '../assets/settings.svg';
// import { HotDogSvg, IconNavClimate } from '../assets/svgs';

const Nav = () => {
  let location = useLocation();

  const path = location.pathname;
  let hideNav = false;

  if ((path === '/')

  ) {
    hideNav = true;
  } else {
    hideNav = false;
  }

  return (
    <nav className="nav" style={{
        display: hideNav && 'none'
        }}
    >
      <NavLink 
        to="/mainpage" 
        className={({ isActive }) => (isActive ? "active" : "inactive")} 
      >
        {/* <img src={imgMachine} alt="machine" /> */}
        <div className="div_a">Main</div>
      </NavLink>
      <NavLink to="/mainpage/counter" className={({ isActive }) => (isActive ? "active" : "inactive")}>
        {/* <img src={imgSettings} alt="system" /> */}
        <div className="div_a">Counter</div>
      </NavLink>
      <NavLink to="/mainpage/service" className={({ isActive }) => (isActive ? "active" : "inactive")}>
        {/* <img src={imgSupport} alt="system" /> */}
        <div className="div_a">service</div>
      </NavLink>
      <NavLink to="/mainpage/embedded" className={({ isActive }) => (isActive ? "active" : "inactive")}>
        {/* <img src={imgSupport} alt="system" /> */}
        <div className="div_a">nexSci</div>
      </NavLink>
      <NavLink to="/mainpage/docs" className={({ isActive }) => (isActive ? "active" : "inactive")}>
        {/* <img src={imgSupport} alt="system" /> */}
        <div className="div_a">ForesterDocs</div>
      </NavLink>
    </nav>
  )
}

export default Nav
